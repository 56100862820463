import edtech from "../src/img/online-course.png";
import logistics from "../src/img/truck.png";
import healthCare from "../src/img/healthcare.png";
import telecom from "../src/img/customer-service.png";
import ecommerce from "../src/img/ecommerce.png";
import media from "../src/img/social-media.png";
import legal from "../src/img/auction.png";
import policy from "../src/img/policy.png";

const Sdata = [
    {
        imgsrc : edtech,
        title : "Edtech",
        text : "Transform learning with modern-age technology resources with us. Connect with our team to work on your EdTech Idea.",
    },
    {
        imgsrc : logistics,
        title : "Logistics",
        text : "A complete cost-effective digital strategies and management IT solutions for your logistics business growth. Talk with our experts now!",
    },
    {
        imgsrc : healthCare,
        title : "HealthCare",
        text : "Get effective, efficient and affordable IT & digital consulting services from the experts to boom your healthcare business growth with the result-oriented.",
    },
    {
        imgsrc : telecom,
        title : "Telecom",
        text : "Our telecom Services provide an end-to-end solutions framework approach to increase efficiency, drive cost reductions and foster innovation.",
    },
    {
        imgsrc : ecommerce,
        title : "E-Commerce",
        text : "Need end-to-end IT solutions for your eCommerce business?  Get in touch with us for 360-degree digital solutions to solve your business and technology needs.",
    },
    {
        imgsrc : media,
        title : "Social Media",
        text : "Get our high-end social media solutions for all kind of businesses. Investigate Our Eminence of Delivering Proven-Quality Social Media Solutions.",
    },
    {
        imgsrc : legal,
        title : "Legal",
        text : "Get our highly flexible IT consulting delivery solution that minimizes risk and optimizes output and value. Get free consultation now!",
    },
    {
        imgsrc : policy,
        title : "IP",
        text : "Not sure what you need yet?  GBC help you keep pace in the ever-changing digital world with our reliable IP solutions for versatile business demands.",
    },
];

export default Sdata;