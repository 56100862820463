import React from "react";
import {Link} from "react-router-dom";
import Sdata from "./Sdata";
import Card from "./Card";
import Axios from "axios";
import { useState, useEffect } from "react";

const Home = () => {
	const url = "https://virtuelegalservices.com/api/testing"

	const [data, setData] = useState({
		name: '',
		j_email: '',
		j_phone: '',
		j_profile: '',
		j_experience: '',
		j_file: '',
	});

	const [formErrors, setFormErrors] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const InputEvent = (event) => {
        const newdata = {...data}
        newdata[event.target.id] = event.target.value
        setData(newdata)
        console.log(newdata)
        
    };
    const formSubmit = (e) =>{
        e.preventDefault();
        setFormErrors(validate(data));
        setIsSubmit(true);
        Axios.post(url,{
            name : data.name,
            j_email : data.j_email,
            j_phone : data.j_phone,
            j_profile : data.j_profile,
			j_experience : data.j_experience,
			j_file : data.j_file,
			
        })
        .then(res=>{
            console.log(res.data)
        })
    };
	useEffect(() => {
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(data);
        }
       
    }, [formErrors]);

	const validate = (values) => {
        const errors = {};
        const rejex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^[0-9]{10})+$/;
        
        if(!values.name){
            errors.name ="Full Name is required";
        }
        if(!values.j_email){
            errors.j_email ="Email is required";
        } else if(!rejex.test(values.j_email)){
            errors.j_email ="This is not a valid Format";
        }

        if(!values.j_phone){
            errors.j_phone ="Phone Number is required";
        } else if(!rejex.test(values.j_phone)){
            errors.j_phone ="This is not a valid Format";
        }
        if(!values.j_profile){
            errors.j_profile ="Message is required";
        }
		if(!values.j_experience){
            errors.j_experience ="Message is required";
        }
		if(!values.j_file){
            errors.j_file ="Message is required";
        }
        return errors;
    };

	return (
		<>
		
		<header>
			<section className="mainbgimg d-flex align-items-center">
				<div className="container main-container">
					<div className="row align-items-center">
						<div className="col-12 col-lg-6 headerleft">
							<h1 className="mainheading">Provide High Quality <br/> Business Solutions</h1>
							<p className="mainpara">We understand the importance of industry knowledge and work on the latest technologies, helping you transform your business with better ROI. </p>
							 <Link to="/about" className="readmorebtn">Read More</Link>
							 <Link to="/contact" className="contactbtn">Contact Us</Link>
						</div>
					</div>
				</div>
			</section>
		</header>
		<section className="aboutsection">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 col-xs-12 text-center">
						<img src="images/whowe.png"  className="img-fluid " alt="aboutimg" />
					</div>
					<div className="col-lg-5 col-xs-12 aboutcontent pt-4">
						<h2>Who We Are</h2>
						<p>India’s best business consulting platform delivering end to end IT solutions and digital transformation which add value and productivity to enterprises. </p>
						 <Link to="/about" className="readmorebtnbig">Read More</Link>
					</div>
				</div>
			</div>
		</section>
		<section className="services_section">
			<div className="container">
				<div className="row">
					<h2 className="text-center headingmain pb-4">Our Services</h2>
				</div>
				<div className="row gy-4">
				{
					Sdata.map((val, ind) => {
						return <Card 
							imgsrc={val.imgsrc}
							title={val.title}
							text={val.text}
						/>
					})
				}
				</div>
			</div>
		</section>
		<section className="jobsection">
			<div className="container">
				
				<div className="row ">
					<div className="col-lg-4 col-xs-12">
					  <img src="images/here.png"  className="img-fluid  mt30" alt="cvhere" />
					</div>
					<div className="col-lg-8 col-xs-12 mt-3">
					<h1 className="cvheading">Boost your Career with us!<br/> Drop your CV here</h1>
						<form className="dropcv mb30" onSubmit={formSubmit}>
						{Object.keys(formErrors).length === 0 && isSubmit ? (<div className="messageclas">Form Submitted Successfully</div>) : (<pre></pre>)}
							<div className="row">
								<div className="col-lg-6 col-xs-12">
									<div className="form-floating mb-3">
										<input type="text" className="form-control" id="name" name="name" placeholder="Name" value={data.name} onChange={InputEvent} />
										<label>Name</label>
										<p className="errortext">{formErrors.name}</p>
										</div>
										<div className="form-floating mb-3">
										<input type="email" className="form-control" id="j_email" name="j_email" placeholder="Email" value={data.j_email} onChange={InputEvent} />
										<label>Email address</label>
										<p className="errortext">{formErrors.j_email}</p>
										</div>
										<div className="form-floating mb-3">
										<input type="number" className="form-control" id="j_phone" name="j_phone" placeholder="Phone Number" value={data.j_phone} onChange={InputEvent} />
										<label>Phone Number</label>
										<p className="errortext">{formErrors.j_phone}</p>
									</div>
								</div>
								<div className="col-lg-6 col-xs-12">
									<div className="form-floating mb-3">
									<input type="text" className="form-control" id="j_profile" name="j_profile" placeholder="Profile Applied For:" value={data.j_profile} onChange={InputEvent} />
									<label>Profile Applied For:</label>
									<p className="errortext">{formErrors.j_profile}</p>
									</div>
									<div className="form-floating mb-3">
									<input type="text" className="form-control" id="j_experience" name="j_experience" placeholder="Experience" value={data.j_experience} onChange={InputEvent} />
									<label>Experience</label>
									<p className="errortext">{formErrors.j_experience}</p>
									</div>
									<div className="form-floating mb-3">
									<input type="File" className="form-control choosefile" name="j_file" id="j_file" value={data.j_file} onChange={InputEvent} />
									<p className="errortext">{formErrors.j_file}</p>
									</div>
								</div>
							</div>
							<button className="btn submitbtn w-100">Submit</button>
						</form>
					</div>
					
				</div>
			</div>
		</section>
		</>
	);
};

export default Home;