import React from "react";
import { useState } from "react";
import JobModal from "./JobModal";

const Jobs = () => {
    
   
	return (
		<>
		
				<section className="mainbgimgjob d-flex align-items-center">
				<div className="container main-container">
					<div className="row align-items-center">
						<div className="col-12 col-lg-12 headercenter">
							<h1 className="mainheading">Join Our Team</h1>
							
						</div>

					</div>
				</div>
			</section>
		<section className="about2sec ab_gbc">
			<div className="container">
                
                <div className="row">
                    <h2 className="jobheading">Latest Job Openings</h2>
                </div>
				<div className="row">
                    <div className="col-lg-9 mx-auto">

                    
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Account Executive
                        </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <h3>Job Description</h3>
                            <ul>
                                <li>For USA Accounting</li>
                                <li>Account Background prefer (tally)</li>
                                <li>Freshers can apply as well</li>
                                <li>Vacancy : 5</li>
                            </ul>
                            <button className="applybtn btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Apply Now</button>
                             
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Voice Based Sales Process
                        </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        <h3>Job Description</h3>
                            <ul>
                                <li>Shift Timings : 05:30 PM to 02:30 AM</li>
                                <li>Freshers can apply as well</li>
                                <li>Vacancy : 20</li>
                            </ul>
                            <button className="applybtn btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Apply Now</button>
                             
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            OPS Manager
                        </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        <ul>
                                <li>Shift Timings : 05:30 PM to 02:30 AM</li>
                                <li>Sales International Process</li>
                                <li>Vacancy : 2</li>
                                <li>Experience : 5 Years</li>
                            </ul>
                            <button className="applybtn btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Apply Now</button>
                            
                        </div>
                        </div>
                    </div>
                    <JobModal/>
                    </div>
			    </div>
                </div>
            </div>
		</section>
		</>
	);
};

export default Jobs;