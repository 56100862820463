import React from "react";
import Sdata from "./Sdata";
import Card from "./Card";

const Services = () => {
	return (
		<>
		
		<header>
			<section className="mainbgimgabout d-flex align-items-center">
				<div className="container main-container">
					<div className="row align-items-center">
						<div className="col-12 col-lg-6 headerleft">
							<h1 className="mainheading">Services</h1>
							
						</div>

					</div>
				</div>
			</section>
		</header>
		<section className="services_section">
			<div className="container">
				<div className="row">
					<h2 className="text-center headingmain pb-4">Our Services</h2>
				</div>
				<div className="row gy-4">
				{
					Sdata.map((val, ind) => {
						return <Card 
							imgsrc={val.imgsrc}
							title={val.title}
							text={val.text}
						/>
					})
				}
				</div>
			</div>
		</section>
		</>
	);
};

export default Services;