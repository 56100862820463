import React, {useState} from "react";
import "./style.css";
import { Link} from "react-router-dom";

const Navbar = () => {

    const[show, setShow] = useState(false);

	return <> 
	<section className="navbar-bg">
	<nav className="navbar navbar-expand-lg navbar-light ">
  <div className="container">
    <Link to="/"  className="navbar-brand"> <img src="images/logo.png" alt="logo" /></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setShow(!show)}>
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className={`collapse navbar-collapse ${show ? "show" : ""}`} >
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navlist">
        <li className="nav-item">
          <Link to="/" className="nav-link active">Home</Link>
        </li>
        <li className="nav-item">
         <Link to="/about" className="nav-link">About</Link>
        </li>
         <li className="nav-item">
         <Link to="/services" className="nav-link">Services</Link>
        </li>
         <li className="nav-item">
          <Link to="/jobs" className="nav-link">Jobs</Link>
        </li>
      </ul>
      <form className="d-flex">
        
        <Link to="/contact" className="btn  btnstyle">Contact Us</Link>
      </form>
    </div>
  </div>
</nav>
</section>

 </>;
};

export default Navbar;