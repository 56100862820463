import React from "react";
import {Link} from "react-router-dom";

const Footer = () => {
	return (
		<>
		
		<footer>
			<section className="footerwrap">
				<div className="container ">
					<div className="row">
						<div className="col-lg-4 col-xs-12">
							<img src="images/logo.png" className="img-fluid" alt="logo" />
							<p className="text-white px-3">India’s best business consulting platform delivering end to end IT solutions and digital transformation which add value and productivity to enterprises.</p>
						</div>
						<div className="col-lg-4 col-xs-12 footerlinks">
						<h1 className="footerheading">Quick Links</h1>
							<div className="row">
								<div className="col-6">
								  <ul>
									<li><Link to="/" className="nav-link active">Home</Link></li>
									<li><Link to="/about" className="nav-link ">About</Link></li>
									
								  </ul>
								</div>
								<div className="col-6">
								  <ul>
									
									<li><Link to="/jobs" className="nav-link ">Jobs</Link></li>
									<li><Link to="/contact" className="nav-link ">Contact</Link></li>
								  </ul>
								</div>
							</div>
							
						</div>
						<div className="col-lg-4 col-xs-12">
						<h1 className="footerheading">Email Subscriber</h1>
							<div className="input-group mb-3">
							  <input type="text" className="form-control" placeholder=""  aria-describedby="basic-addon2" />
							  <button className="input-group-text subs_button btn" id="basic-addon2">Subscribe</button>
							</div>
							<div className="contactdet">
								<p className="mb-0 text-white">Email : Jobs@gbcinfotech.com</p>
								<p className="mb-0 text-white">Phone : 9872005759</p>
							</div>
						</div>

					</div>
				</div>
			</section>
			<section className="copyrightsec">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-xs-12">
							<p>Copyright 2022 Global Business Consultancy, All Right Reserved</p>
						</div>
						<div className="col-lg-6 col-xs-12 text-end">
						<ul className="socialicons">
							<li>
								<a href="#"> <i class="bi bi-facebook"></i></a>
							</li>
							<li>
								<a href="#"> <i class="bi bi-linkedin"></i></a>
							</li>
							<li>
								<a href="#"> <i class="bi bi-twitter"></i></a>
							</li>
							<li>
								<a href="#"> <i class="bi bi-instagram"></i></a>
							</li>
						</ul>
						</div>
					</div>
				</div>
			</section>
		</footer>
		</>
	);
};

export default Footer;