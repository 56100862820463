import React from "react";

const About = () => {
	return (
		<>
		
		<header>
			<section className="mainbgimgabout d-flex align-items-center">
				<div className="container main-container">
					<div className="row align-items-center">
						<div className="col-12 col-lg-6 headerleft">
							<h1 className="mainheading">About GBC</h1>
							<p className="mainpara">India’s best business consulting platform delivering end to end IT solutions and digital transformation which add value and productivity to enterprises.</p>
						</div>

					</div>
				</div>
			</section>
		</header>
		<section className="about2sec ab_gbc">
			<div className="container ">
				<div className="row">
                <div className="col-lg-5 col-xs-12">
                    <img src="images/about1.jpg"  className="img-fluid" alt="aboutimg" />
                </div>
					<div className="col-lg-7 col-xs-12 ">
					<p>Global Business Consultancy delivers IT and digital consulting services in a variety of different ways: deliverable-based projects, teaming arrangements or role-based engagements for more than 17 years.</p>
					<p>We design, build, implement, and maintain customized internet-related solutions using cutting-edge technologies.</p>
					<p>Global Business Consultancy is the best online platform for all kinds of businesses. We cater to specific professional needs by hiring world class talented developers, planners, advertisers and more. We give customised suggestions, ideas and innovative solutions for directing your business towards climbing trends.</p>
					</div>
                    
				</div>
			</div>
		</section>
		</>
	);
};

export default About;