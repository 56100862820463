import React, { useState, useEffect } from "react";
import Axios from "axios";

const Contact = () => {
    const url = "https://virtuelegalservices.com/api/add"
  
    const [data, setData] = useState({
        fullname:'',
        email:'',
        phone:'',
        message:'',
    });

    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const InputEvent = (event) => {
        const newdata = {...data}
        newdata[event.target.id] = event.target.value
        setData(newdata)
        console.log(newdata)
        
    };
    const formSubmit = (e) =>{
        e.preventDefault();
        setFormErrors(validate(data));
        setIsSubmit(true);
        Axios.post(url,{
            fullname : data.fullname,
            email : data.email,
            phone : data.phone,
            message : data.message,
        })
        .then(res=>{
            console.log(res.data)
        })
    };
    useEffect(() => {
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(data);
        }
       
    }, [formErrors]);

    const validate = (values) => {
        const errors = {};
        const rejex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^[0-9]{10})+$/;
        
        if(!values.fullname){
            errors.fullname ="Full Name is required";
        }
        if(!values.email){
            errors.email ="Email is required";
        } else if(!rejex.test(values.email)){
            errors.email ="This is not a valid Format";
        }

        if(!values.phone){
            errors.phone ="Phone Number is required";
        } else if(!rejex.test(values.phone)){
            errors.phone ="This is not a valid Format";
        }
        if(!values.message){
            errors.message ="Message is required";
        }
        return errors;
    };

   

    
	return (
		<>
		
			<section className="mainbgimgcontact d-flex align-items-center">
				<div className="container main-container">
					<div className="row align-items-center">
						<div className="col-12 col-lg-12 text-center">
							<h1 className="mainheading">Contact Us</h1>
							
						</div>

					</div>
				</div>
			</section>
            <section className="contactwrap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-xs-12 contactheading">
                            <h2>Leave us your info</h2>
                            <p>and we will get back to you.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-xs-12 cotactdeail">
                            <ul>
                                <li><strong>Phone:</strong> 9872005759</li>
                                <li><strong>Email:</strong> Jobs@gbcinfotech.com</li>
                                <li><strong>Location:</strong> Level IV.  Block No. 24, SDA Complex, Kasumpti, Shimla-9</li>

                            </ul>
                            <iframe  title="map" src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1708.56897741013!2d77.18040110811596!3d31.078089395379507!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sLevel%20IV.%20%20Block%20No.%2024%2C%20SDA%20Complex%2C%20Kasumpti%2C%20Shimla-9!5e0!3m2!1sen!2sin!4v1654862905722!5m2!1sen!2sin" width="100%" height="300"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="contact-box">
                                {Object.keys(formErrors).length === 0 && isSubmit ? (<div className="messageclas">Form Submitted Successfully</div>) : (<pre></pre>)}
                            <form onSubmit={formSubmit}>
                                <div className="mb-3">
                                    <label className="form-label">Full Name</label>
                                    <input type="text" className="form-control" id="fullname" name="fullname" value={data.fullname} onChange={InputEvent} />
                                    <p className="errortext">{formErrors.fullname}</p>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Email</label>
                                    <input type="email" className="form-control" id="email" name="email" value={data.email} onChange={InputEvent} />
                                    <p className="errortext">{formErrors.email}</p>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Phone</label>
                                    <input type="text" className="form-control" id="phone" name="phone" value={data.phone} onChange={InputEvent}/>
                                    <p className="errortext">{formErrors.phone}</p>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Message</label>
                                    <textarea className="form-control" rows="3" name="message" id="message" value={data.message} onChange={InputEvent}></textarea>
                                    <p className="errortext">{formErrors.message}</p>
                                </div>
                                <div className="col-12">
                                    <button className=" btn submitbtn w-100" type="submint"> Submit</button>
                                </div>
                            </form>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
		
		</>
	);
};

export default Contact;