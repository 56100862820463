import React from "react";

const Card = (props) => {
	return(
		<>

				<div className="col-md-3 col-10 mx-auto">
					<div className="servicebox">
						<div className="roundimgwrap">
						  <img src={props.imgsrc} className="card-img-top serviceimg" alt={props.imgsrc} />
						</div>
					  <div className="card-body">
					    <h5 className="card-title">{props.title}</h5>
					    <p className="card-text">{props.text}</p>
					    
					  </div>
					</div>
				</div>
				
		</>
	);
};

export default Card;