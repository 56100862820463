import React from "react";

const JobModal = () => {
	return(
		<>

            <div className="modal fade" id="exampleModal"  aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                <div className="modal-header">
                    
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="col-lg-12 col-xs-12 mt-3">
					<h1 className="cvheading">Boost your Career with us!<br/> Drop your CV here</h1>
						<form className="dropcv mb30">
							<div className="row">
								<div className="col-lg-6 col-xs-12">
									<div className="form-floating mb-3">
										<input type="text" className="form-control" id="floatingn" placeholder="Name" />
										<label>Name</label>
										</div>
										<div className="form-floating mb-3">
										<input type="email" className="form-control" id="floatingInput" placeholder="Email" />
										<label>Email address</label>
										</div>
										<div className="form-floating mb-3">
										<input type="number" className="form-control" id="floatingP" placeholder="Phone Number" />
										<label>Phone Number</label>
									</div>
								</div>
								<div className="col-lg-6 col-xs-12">
									<div className="form-floating mb-3">
									<input type="text" className="form-control" id="floatingprof" placeholder="Profile Applied For:" />
									<label>Profile Applied For:</label>
									</div>
									<div className="form-floating mb-3">
									<input type="text" className="form-control" id="floatingprof" placeholder="Experience" />
									<label>Experience</label>
									</div>
									<div className="form-floating mb-3">
									<input type="File" className="form-control choosefile" id="" />
									</div>
								</div>
							</div>
							<button className="btn submitbtn w-100">Submit</button>
						</form>
					</div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button>
                </div>
                </div>
            </div>
            </div>
				
		</>
	);
};

export default JobModal;