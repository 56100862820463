import React from "react";
import Navbar from "./Navbar";
import Home from "./Home";
import About from "./About";
import Jobs from "./Jobs";
import Services from "./Services";
import Contact from "./Contact";
import Footer from "./Footer";
import {Routes, Route} from "react-router-dom";


const App = () => {

  return (
    <>
  <Navbar />
    <Routes>
       <Route exact path="/" element={<Home/>}/>
       <Route exact path="/about" element={<About/>}/>
       <Route exact path="/services" element={<Services/>}/>
        <Route exact path="/jobs" element={<Jobs/>}/>
        <Route exact path="/contact" element={<Contact/>}/>
    </Routes>
    <Footer />
     </>
  );
}

export default App;
